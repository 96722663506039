/* eslint-disable no-console,no-undef, camelcase */
import React from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import styled from "styled-components";
import _ from "lodash";
import { ToastContainer } from "react-toastify";

import PortalV2 from './containers/PortalV2';
import Challenges from './containers/Challenges';
import Events from  './containers/Events';
import Social from  './containers/Social';
import WellnessAssessment from './containers/WellnessAssessment';
import UserProfile from './containers/UserProfile/userProfileMain';
import EmployeeDirectory from './containers/EmployeeDirectory';
import Settings from './containers/Settings';
import Company from  './containers/Company';
import Education from './containers/Education';
import RewardsDashboard from './containers/RewardsDashboard';
import LeaderboardContainer from './containers/Leaderboard/index';
import RestrictedRoute from './components/RestrictedRoute';
import TopBarV3 from './components/TopBarV3';
import SideBarV3 from './components/SideBarV3';
// import LandingFooterPanel from './components/LandingFooterPanel';
import WellnessExpertDetails from './components/WellnessExpertDetails';
import Waiting from './components/Waiting';
import NotFound from './components/NotFound';
import MultiStepForm from './components/MultiStepForm';
import WorkoutHistory from './components/WorkoutHistory';
import NameHeader from './components/NameHeader';
import {
  getUser,
  authenticatedUser,
  logout,
  getAdminUser,
  getUserRole,
  getUserCompanyDetails,
  getUserProfileImage,
  getReadStatusCountForNotification,
  getCompanyBrandingDetails,
  fetchLanguages,
  getRewardPoints,
  getProfileRoles,
  getProfileCompanyDetails,
  getProfileActivityData,
  getProfileChallengeSurveys,
  getProfileInputActivityGoals,
  getProfileMonthlyGoals,
  getProfileThemes,
  getProfileAllThemes
} from "./redux/actions";
import { AllRoutes } from "./utils/constants";
import { checkPermission, getPermissionStatus } from "./utils/methods";
import { getUserPermissions } from "./redux/actions/userProfileActions";
import isNull from "lodash/isNull";
import UserSurveyV1 from "./components/UserSurveyV1";
import { UserSurvey } from "./components";
import NodeTest from './containers/node-testing';
import Manager from "./containers/Manager";
import QuizDetails from "./containers/Education/QuizDetails";
import QuizQuestionsAnswers from "./containers/Education/QuizQuestionsAnswers";
// import {PortalGrid, ContentContainer, ContentStyle, StyledButton} from './components/TopBarV3/style'
// import { UserSurvey } from "./components";

// const hideFooetrPath = ["/company/wellness/create-quiz","/company/surveys/edit-survey-library","/social","/company/events/create","/events/create","/events/create-ondemand","/company/events/create-lunch-learns","/company/events/edit-lunch-learns","/company/challenges/create-activty","/challenges/create-activty","/challenges/edit/","/company/challenges/edit/","/company/challenges/activity-launch","/challenges/activity-launch","/company/challenges/activity-edit","/challenges/activity-edit","/company/surveys/launch-custom","/company/recognition/addcorevalues","/events/edit-ondemand","/events/create-coach","/company/create-health-program","/company/initiative-priority","/company/rewards-management","/company/create_company","/company/surveys/create-survey-library","/company/surveys/launch-custom-survey","/company/surveys/edit-custom-survey", "/profile","/company/edit_company","/wellness-resource/quiz-details","/wellness-resource/quiz-questions"];

const PortalGrid = styled.div`
  display: block;
  position: relative;
  width: 100%;
  height: auto;
  overflow: hidden;
  // max-width: 1600px;
  margin: 0 auto;

  > div:nth-child(2) {
    position: fixed;
    top: 0;
    z-index: 1001;
  }

  > div:nth-child(3) {
    top: 70px;
    position: fixed;
    z-index: 99;
    background: white;
    @media (max-width: 700px) {
      background: unset;
      z-index: 3;
    }
  }

  > div:nth-child(4) {
    padding-top: 70px;
  }

  .Toastify__toast-body {
    margin-right: 20px;
  }
`;

const ContentContainer = styled.div`
  width: 100%;
  float: left;
  position: ${({ position }) => (position ? "relative !important" : "relative")};
  min-height: 800px;
`;

const ContentStyle = styled.div`
  width: calc(100% - ${({ hideContent, isManager }) => (isManager?"0px": hideContent ? "80px" : "200px")}) !important;
  margin-left: ${({ hideContent, isManager }) => (isManager?"0px": hideContent ? "80px" : "200px")} !important;
  float: left !important;
  background-color: ${({ background }) => (background ? "#F7F5EB" : "#F8F8F8")} !important;
  min-height: 800px !important;
  @media (max-width: 950px) {
    margin-left: 0;
    width: 100%;
  }
`;

const StyledButton = styled.button`
  background-color: transparent;
  border: none;
  outline: none;
  position: absolute;
  right: 5px;
`;

class AuthenticatedRoutes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pathValue: "",
      colorData: {},
      hideContent: true,
      responsiveCheck: false,
      headerResponsiveCheck: false,
      currentCompanyId: 1,
      hideEditFooterPath:''
    };
  }
  componentDidMount() {
    const pathArray= this.props.history.location?.pathname?.split("/");
    const modifiedPath = pathArray?.slice(0, -1)?.join('/');
    this.setState({hideEditFooterPath:modifiedPath});
    const {
      history,
      authenticatedUser,
      fetchUserRole,
      fetchUserCompany,
      authenticated,
      getUser,
      fetchUserProfileImage,
      /*fetchCompanyBrandingDetails, userCompany,*/ getUserPermissions,
      fetchLanguages,
      reward, fetchRewardPoints,
      getProfileRoles, getProfileCompanyDetails, getProfileActivityData, getProfileInputActivityGoals, getProfileMonthlyGoals, getProfileThemes, getProfileAllThemes
    } = this.props;
    if (!reward) fetchRewardPoints();

    if (checkPermission()) {
      this.setState({
        pathValue: this.props.history.location.pathname,
      });
      // getReadStatusCountForNotification();
      authenticatedUser();
      fetchUserRole();
      fetchUserCompany();
      fetchLanguages();
      // userCompany === null ? fetchCompanyBrandingDetails(1) : fetchCompanyBrandingDetails(userCompany.id);
      if (
        authenticated ||
        (localStorage && localStorage.getItem("AUTH_TOKEN"))
      ) {
        getUser();
        getProfileRoles();
        getProfileCompanyDetails();
        getProfileActivityData();
        getProfileChallengeSurveys();
        getProfileInputActivityGoals();
        getProfileMonthlyGoals();
        getProfileThemes();
        fetchUserProfileImage();
        fetchUserCompany();
        getProfileAllThemes();
      } else {
        history.push("/user");
      }
    } else {
      alert("Please enable your cookies and localstorage");
      history.push("/");
    }
    if (!isNull(localStorage?.getItem("AUTH_TOKEN"))) {
      getUserPermissions();
    }
  }
  UNSAFE_componentWillReceiveProps() {
    const { user, history } = this.props;
    if (
      user &&
      !user.accepted_privacy_policy &&
      history.location.pathname != "/portal"
    ) {
      history.push("/portal");
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.history.location.pathname != this.state.pathValue) {
      const pathArray= this.props.history?.location?.pathname?.split("/");
      const modifiedPath = pathArray?.slice(0, -1).join('/');
      this.setState({
        pathValue: prevProps.history.location.pathname,
        hideEditFooterPath:modifiedPath
      });
    }


    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
    if (prevProps.colorInfo !== this.props.colorInfo) {
      this.setState({ colorData: this.props.colorInfo });
    }
  }

  onRouteChanged = () => {
    this.props.getReadStatusCountForNotification();
    this.top && this.top.scrollIntoView({ block: "start", behavior: "smooth" });
  };

  hideContentOfSideBar = () => {
    this.setState({ hideContent: !this.state.hideContent });
  };

  handleResponsiveCheck = () => {
    this.setState((prev) => ({
      responsiveCheck: !prev.responsiveCheck,
    }));
  };

  handleAdminResponsiveCheck = () => {
    this.setState((prev) => ({
      headerResponsiveCheck: !prev.headerResponsiveCheck,
    }));
  };

  companyBrandingCallBack = (id) => {
    this.setState({
      currentCompanyId: id,
    });
  };

  render() {
    // let chatElm = document.getElementById("LeadboosterContainer");
    // if (chatElm) {
    //   chatElm.setAttribute("style", "display:none !important");
    // }
    const { currentCompanyId, responsiveCheck, /*hideEditFooterPath*/ } = this.state;
    const {
      companyLogo,
      history,
      logout,
      response,
      networkError,
      isAdmin,
      role,
      userCompany,
      profileImage,
      readStatusCount,
      userPermissions,
      user,
      getLanguages,
    } = this.props;

    // window.console.log("role is inside the auth file",role,  localStorage.getItem("MANAGER"))
    // const StringArray = history.location.pathname.split("/");
    // let hideFooterEventEdit=StringArray[StringArray.length - 1]=="edit"?1:0;
    if (!_.includes(AllRoutes, history.location.pathname.split("/")[1])) {
      return <NotFound />;
    }
    // const managerArray = [
    //   "/manager",
    
    // ];
    const pathArray = [
      "/company",
      /*'/company/reports',*/ "/company/wellbeing",
      // "/company/recognition",
      "/company/engagement",
      // "/company/biometric",
      "/company/admin",
      "/company/monthly_tasks",
    ];
    const portalPath = [
      "/portal",
      "/challenges",
      "/education/adventure",
      "/education/videos-library",
      "/education/recipes",
      "/education/webinar",
      "/profile",
      "/rewards",
      "/community",
      "/events",
      "/leaderboard-page",
    ];
    if (!role || !userCompany || !userPermissions) {
      return <Waiting />;
    }
    const isManagerUser=localStorage.getItem('isManagerRole')
    return (
      <PortalGrid>
        <div ref={(node) => (this.top = node)} />
        <TopBarV3
          logout={() => logout(history)}
          history={history}
          isAdmin={
            (role === "ADMIN" && userCompany.id === null) ||
            role === "WELLNESS_ADMIN"
          }
          isManager={
            role === "MANAGER"
          }
          companyLogo={(companyLogo && companyLogo.header_logo) || null}
          userDetails={user}
          profileImage={profileImage}
          readStatusCount={readStatusCount}
          adminPortalAccess={getPermissionStatus(
            "Access admin portal",
            userPermissions
          )}
          colorData={this.state.colorData}
          hideContentOfSideBar={this.hideContentOfSideBar}
          handleResponsiveCheck={this.handleResponsiveCheck}
          handleAdminResponsiveCheck={this.handleAdminResponsiveCheck}
          getLanguages={getLanguages}
          companyId={userCompany && userCompany.id}
        />
        {(isManagerUser =="Manager" && this.props.isManagerUser)? null :
          <SideBarV3
            pathValue={this.state.pathValue}
            response={response}
            history={history}
            networkError={networkError}
            isManager={
              role === "MANAGER" || (role === "ADMIN" && userCompany.id === null) 
            }
            role={role}
            userPermissions={userPermissions}
            colorData={this.state.colorData}
            hideContent={this.state.hideContent}
            hideTab={this.props.colorInfo}
            colorInformation={this.props.colorInfo}
            currentCompanyId={currentCompanyId}
          />
        }
        <ContentContainer position={isManagerUser =="Manager" && this.props.isManagerUser}>
          {portalPath.includes(history.location.pathname) ? null : null}
          {pathArray.includes(history.location.pathname) ? (
            <NameHeader
              userDetails={response && response.user}
              isAdmin={role === "ADMIN" && userCompany.id === null}
              role={role === "WELLNESS_ADMIN"}
              userCompany={userCompany}
              history={history}
            />
          ) : null}

          { (isManagerUser =="Manager" && this.props.isManagerUser) ? null :
            <SideBarV3
              className="sidebar"
              pathValue={this.state.pathValue}
              response={response}
              history={history}
              networkError={networkError}
              role={role}
              userPermissions={userPermissions}
              colorData={this.state.colorData}
              hideContent={this.state.hideContent}
            />
          }
          <ContentStyle isManager={(isManagerUser =="Manager" && this.props.isManagerUser)}  hideContent={this.state.hideContent} background={ (history.location.pathname.includes("/company/survey")||history.location.pathname.includes("/challenges"))} >
            <Switch>
              <RestrictedRoute
                path="/manager"
                component={Manager}
                check={responsiveCheck}
              />
              <RestrictedRoute
                path="/portal"
                component={PortalV2}
                check={responsiveCheck}
              />
              <RestrictedRoute path="/events" component={Events} />
              <RestrictedRoute
                path="/challenges"
                component={Challenges}
                userCompany={userCompany}
              />
              <RestrictedRoute
                path="/social"
                component={Social}
                role={role}
                companyId={userCompany.id}
                user={user}
              />
              <RestrictedRoute path="/education" component={Education} />
              <RestrictedRoute
                path="/rewards"
                component={RewardsDashboard}
                userCompany={userCompany}
                role={role}
              />
              <RestrictedRoute
                path="/wellness-assessment"
                component={WellnessAssessment}
              />
              <RestrictedRoute
                path="/profile/biometric/:id/multi-step/physician-form"
                component={MultiStepForm}
                history={history}
                profile={1}
              />
              <RestrictedRoute
                path="/profile"
                component={UserProfile}
                companyId={userCompany.id}
              />
              <RestrictedRoute path="/profile/:uid" component={UserProfile} />
              <RestrictedRoute
                path="/company"
                component={Company}
                isAdmin={
                  (isAdmin && isAdmin.toString()) ||
                  localStorage.getItem("ADMIN_USER")
                }
                role={role}
                userCompany={userCompany}
                companyBrandingCallBack={this.companyBrandingCallBack}
              />
              <RestrictedRoute
                path="/wellness-resource/quiz-details/:id"
                component={QuizDetails}
                userCompany={userCompany}
                role={role}
              />
              <RestrictedRoute
                path="/wellness-resource/quiz-questions"
                role={role}
                component={QuizQuestionsAnswers}
                userCompany={userCompany}
              />

              <RestrictedRoute path="/settings" component={Settings} />
              <RestrictedRoute
                path="/community"
                component={EmployeeDirectory}
                userCompany={userCompany}
                role={role}
              />
              <RestrictedRoute
                path="/wellness-expert/:id"
                component={WellnessExpertDetails}
              />
              <RestrictedRoute path="/survey" component={this.props.location?.state?.surveyCategory === "challengeSurvey" ? UserSurvey : UserSurveyV1} />
              <RestrictedRoute
                path="/workout-history"
                component={WorkoutHistory}
              />
              <RestrictedRoute
                path="/leaderboard"
                component={LeaderboardContainer}
                userCompany={userCompany}
              />
              <RestrictedRoute path="/node" component={NodeTest} />
              <Route component={NotFound} />
            </Switch>
          </ContentStyle>
        </ContentContainer>
        {/* {hideFooterEventEdit|| hideFooetrPath.includes(history.location.pathname) || hideFooetrPath.includes(hideEditFooterPath) ?null:
          <LandingFooterPanel
            hideContent={this.state.hideContent}
            isInsidePages
            isUser={true}
            companyLogo={(companyLogo && companyLogo.footer_logo) || null}
          />} */}
        <ToastContainer closeButton={<StyledButton > x </StyledButton>} />
      </PortalGrid>
    );
  }
}

AuthenticatedRoutes.propTypes = {
  history: PropTypes.object.isRequired,
  getUser: PropTypes.func.isRequired,
  response: PropTypes.object,
  errorMessage: PropTypes.string,
  message: PropTypes.string,
  logout: PropTypes.func.isRequired,
  location: PropTypes.object.isRequired,
  networkError: PropTypes.string,
  authenticatedUser: PropTypes.func.isRequired,
  authenticated: PropTypes.string.isRequired,
  getAdminUser: PropTypes.func.isRequired,
  isAdmin: PropTypes.bool,
  fetchUserRole: PropTypes.func.isRequired,
  role: PropTypes.string.isRequired,
  fetchUserCompany: PropTypes.func.isRequired,
  userCompany: PropTypes.object,
  companyLogo: PropTypes.object,
  readStatusCount: PropTypes.object,
  user: PropTypes.object,
  fetchUserProfileImage: PropTypes.func,
  getReadStatusCountForNotification: PropTypes.func,
  profileImage: PropTypes.string,
  getUserPermissions: PropTypes.func,
  userPermissions: PropTypes.array,
  fetchCompanyBrandingDetails: PropTypes.func.isRequired,
  colorInfo: PropTypes.object,
  fetchLanguages: PropTypes.func,
  getLanguages: PropTypes.array,
  fetchRewardPoints: PropTypes.func.isRequired,
  reward: PropTypes.object,
  getProfileRoles: PropTypes.func,
  getProfileCompanyDetails: PropTypes.func,
  getProfileActivityData: PropTypes.func,
  getProfileChallengeSurveys: PropTypes.func,
  getProfileInputActivityGoals: PropTypes.func,
  getProfileMonthlyGoals: PropTypes.func,
  getProfileThemes: PropTypes.func,
  getProfileAllThemes: PropTypes.func,
  isManagerUser: PropTypes.bool
};

const mapStateToProps = (state) => ({
  response: state.profileData.response,
  errorMessage: state.profileData.errorMessage,
  message: state.auth.message,
  networkError: state.profileData.networkError,
  authenticated: state.auth.token,
  isAdmin: state.auth.isAdmin,
  role: state.auth.role,
  userCompany: state.profileData.userCompany,
  companyLogo: state.profileData.companyLogo,
  user: state.profileData.user,
  profileImage: state.profileData.profileImage,
  readStatusCount: state.userNotification.readStatusCount,
  userPermissions: state.profileData.userPermissions,
  colorInfo: state.wellnessDashboard.companyBrandingDetails,
  getLanguages: state.companyDashboard.getLanguages,
  isManagerUser: state.profileData.isManagerUser
});

const mapDispatchToProps = (dispatch) => ({
  getUser: () => dispatch(getUser()),
  logout: (history) => dispatch(logout(history)),
  authenticatedUser: () => dispatch(authenticatedUser()),
  getAdminUser: () => dispatch(getAdminUser()),
  fetchUserRole: () => dispatch(getUserRole()),
  fetchUserCompany: () => dispatch(getUserCompanyDetails()),
  fetchUserProfileImage: () => dispatch(getUserProfileImage()),
  getReadStatusCountForNotification: () =>
    dispatch(getReadStatusCountForNotification()),
  fetchCompanyBrandingDetails: (id) => dispatch(getCompanyBrandingDetails(id)),
  getUserPermissions: () => dispatch(getUserPermissions()),
  fetchLanguages: () => dispatch(fetchLanguages()),
  fetchRewardPoints: () => dispatch(getRewardPoints()),
  getProfileRoles: () => dispatch(getProfileRoles()),
  getProfileCompanyDetails: () => dispatch(getProfileCompanyDetails()),
  getProfileActivityData: () => dispatch(getProfileActivityData()),
  getProfileChallengeSurveys: () => dispatch(getProfileChallengeSurveys()),
  getProfileInputActivityGoals: () => dispatch(getProfileInputActivityGoals()),
  getProfileMonthlyGoals: () => dispatch(getProfileMonthlyGoals()),
  getProfileThemes: () => dispatch(getProfileThemes()),
  getProfileAllThemes: () => dispatch(getProfileAllThemes())
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(AuthenticatedRoutes)
);